import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardNavigation from './components/DashboardNavigation';
import LoginPage from './pages/LoginPage';
import TwoFactorAuth from './pages/twoFactorAuthPage';
import UserFile from './forms/UserFile';


const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardNavigation />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/twoFactorAuthPage" element={<TwoFactorAuth />}/>
        <Route path="/user" elemen t={<UserFile />} />
        {/*<Route path="/user/:id" component={UserFile} /> */}
        {/* Todo routing with id detail ansicht*/}
      </Routes>
    </Router>
  );
};

export default AppRouter;
