import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../interface/api/api';
import { commonClient } from '../../interface/clients';

interface Provider {
  Note: any;
  BusinessNumber: any;
  CentreProvider: any;
  Address: any;
  subscription_fk(subscription_fk: any): string;
  Subscription: any;
  centre_id: string;
  name: string;
  email: string;
  phone: string;
  countryPhone: string;
  User: any;
}

interface OrderDetail {
  date: string;
  frequency: string;
  added: number;
  remainingDays: number;
}

interface StripeOrders {
  BOOKING: OrderDetail[];
  TARIF590: any[];
  SMS: any[];
}

interface SubscriptionSummary {
  yearly: number;
  monthly: number;
  trial: number;
  increasedYearlyYear: number;
  increasedMonthlyYear: number;
  increasedTrialYear: number;
  totalIncreasedYearly: number;
}

interface ProvidersState {
  data: Provider[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  subscriptionDetails: any;
  stripeOrders: StripeOrders | null;
  subscriptionSummary: SubscriptionSummary | null;
}

interface InvitationData {
  User: {
    email: string;
    phone: string;
    countryPhone: string;
  };
  senderId: string;
}

const initialState: ProvidersState = {
  data: [],
  status: 'idle',
  error: null,
  subscriptionDetails: null,
  stripeOrders: null,
  subscriptionSummary: null,
};

export const fetchProviders = createAsyncThunk('providers/fetchProviders', async () => {
  const response = await api.getTable('Centre');
  return response.data;
});

export const fetchSubscriptionDetails = createAsyncThunk(
  'providers/fetchSubscriptionDetails',
  async (stripeCustomerId: string, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/api/adminPanel/generateFormattedStripeSubList`, {
        stripeCustomerId,
        mainSub: true,
        invoiceSub: true,
        smsSub: true,
      });

      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to fetch subscription details');
    }
  }
);

export const fetchSubscriptionSummary = createAsyncThunk(
  'providers/fetchSubscriptionSummary',
  async (_, { rejectWithValue }) => {
    try {
      const response = await commonClient.get(`/api/adminPanel/subscriptionSummary`);

      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to fetch subscription summary');
    }
  }
);

export const setInvoiceCredit = createAsyncThunk(
  'providers/setInvoiceCredit',
  async (SMSData: { subscriptionId: string; amount: number }, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/api/adminPanel/invoiceCredit`, SMSData)

      const data = response;
      return { data, subscriptionId: SMSData.subscriptionId };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to set invoice credit');
    }
  }
);

export const setSMSCredit = createAsyncThunk(
  'providers/setSMSCredit',
  async (SMSData: { subscriptionId: string; amount: number }, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/api/adminPanel/smsCredit`, SMSData);
      
      const data = await response;
      return { data, subscriptionId: SMSData.subscriptionId };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to set SMS credit');
    }
  }
);

interface UpdateTableEntryParams {
  tableName: string;
  id: any;
  data: any;
}

export const updateTableEntry = createAsyncThunk(
  'providers/updateTableEntry',
  async ({ tableName, id, data }: UpdateTableEntryParams, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/api/adminPanel/table/${tableName}/id/${id}/updateTable`, {data});

      const responseData = response;
      return responseData;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to update table entry');
    }
  }
);

export const updateTrialEndDate = createAsyncThunk(
  'providers/updateTrialEndDate',
  async ({ subscriptionId, date }: { subscriptionId: string; date: any }, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/api/adminPanel/trialEnd`,{
        subscriptionId,
        date,
      });
      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to update trial end date');
    }
  }
);

export const postNote = createAsyncThunk(
  'providers/postNote',
  async (noteData: { noteText: string; category: string; status: string; data: { _id: string } }, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/api/adminPanel/note`, noteData);
      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to post note');
    }
  }
);

export const updateNote = createAsyncThunk(
  'providers/updateNote',
  async ({ id, noteData }: { id: string; noteData: { noteText: string; category: string; status: string; data: { _id: string } } }, { rejectWithValue }) => {
    try {
      const response = await commonClient.patch(`/api/adminPanel/note/${id}`, noteData);

      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to update note');
    }
  }
);

export const createAndInviteUser = createAsyncThunk(
  'providers/createAndInviteUser',
  async ({ centreId, invitationData }: { centreId: any; invitationData: InvitationData }, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/invitations/${centreId}/createAndInvite`, invitationData);

      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to create and invite user');
    }
  }
);

export const fetchStripeOrders = createAsyncThunk(
  'providers/fetchStripeOrders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await commonClient.get(`/api/adminPanel/getFormattedStripeOrders`);

      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to fetch stripe orders');
    }
  }
);

export const resetPassword = createAsyncThunk(
  'providers/resetPassword',
  async (newPassword: { password: string }, { rejectWithValue }) => {
    try {
      const response = await commonClient.post(`/api/auth/resetPassword`, newPassword);

      const data = response;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to reset password');
    }
  }
);

const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProviders.fulfilled, (state, action: PayloadAction<Provider[]>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch providers';
      })
      .addCase(fetchSubscriptionDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscriptionDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.subscriptionDetails = action.payload;
      })
      .addCase(fetchSubscriptionDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch subscription details';
      })
      .addCase(fetchSubscriptionSummary.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscriptionSummary.fulfilled, (state, action) => {
        let ssPayload:any = action.payload
        state.status = 'succeeded';
        state.subscriptionSummary = ssPayload;
      })
      .addCase(fetchSubscriptionSummary.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch subscription summary';
      })
      .addCase(setInvoiceCredit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setInvoiceCredit.fulfilled, (state, action: PayloadAction<{ data: any; subscriptionId: string }>) => {
        state.status = 'succeeded';
        state.data = state.data.map(provider =>
          provider.Subscription?.subscription_fk === action.payload.subscriptionId
            ? { ...provider, Subscription: { ...provider.Subscription, invoiceCredit: action.payload.data } }
            : provider
        );
      })
      .addCase(setInvoiceCredit.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to set invoice credit';
      })
      .addCase(setSMSCredit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setSMSCredit.fulfilled, (state, action: PayloadAction<{ data: any; subscriptionId: string }>) => {
        state.status = 'succeeded';
        state.data = state.data.map(provider =>
          provider.Subscription?.subscription_fk === action.payload.subscriptionId
            ? { ...provider, Subscription: { ...provider.Subscription, smsCredit: action.payload.data } }
            : provider
        );
      })
      .addCase(setSMSCredit.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to set SMS credit';
      })
      .addCase(updateTableEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTableEntry.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateTableEntry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update table entry';
      })
      .addCase(updateTrialEndDate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTrialEndDate.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateTrialEndDate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update trial end date';
      })
      .addCase(postNote.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postNote.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
      })
      .addCase(postNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to post note';
      })
      .addCase(updateNote.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateNote.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to patch note';
      })
      .addCase(createAndInviteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAndInviteUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
      })
      .addCase(createAndInviteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create and invite user';
      })
      .addCase(fetchStripeOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStripeOrders.fulfilled, (state, action) => {
        let fsoPayload:any = action.payload
        state.status = 'succeeded';
        state.stripeOrders = fsoPayload;
      })
      .addCase(fetchStripeOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch stripe orders';
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to reset password';
      });
  },
});

export default providersSlice.reducer;
