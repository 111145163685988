import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviders } from '../features/providers/providersSlice';
import { fetchUsers } from '../features/providers/userSlice';
import { fetchSubscriptions } from '../features/providers/subcriptionsSlice';
import { fetchSubscriptionSummary } from '../features/providers/providersSlice';
import { Card, Table, Tag, Pagination } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TeamOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { RootState } from '../store';
import { AppDispatch } from '../store';
import { useProviderContext } from '../components/ProviderContext';

interface Provider {
  centre_id: string;
  name: string;
  subscription_fk: string;
}

interface Subscription {
  subscription_id: string;
  smsCountLeft: number;
}

interface DataType {
  key: string;
  ID: string;
  Name: string;
  Users: string;
  SMS: string;
  Invoices: string;
  Status: string;
}

interface FormDashboardMainProps {
  onAccountSelect?: () => void;
}

const FormDashboardMain: React.FC<FormDashboardMainProps> = ({ onAccountSelect }) => {
  const dispatch = useDispatch<AppDispatch>();
  const providers = useSelector((state: RootState) => state.providers.data);
  const subscriptions = useSelector((state: RootState) => state.subscriptions.data);
  const users = useSelector((state: RootState) => state.users.data);
  const subscriptionSummary = useSelector((state: RootState) => state.providers.subscriptionSummary);
  const providersStatus = useSelector((state: RootState) => state.providers.status);
  const subscriptionsStatus = useSelector((state: RootState) => state.subscriptions.status);
  const usersStatus = useSelector((state: RootState) => state.users.status);

  const { setSelectedProviderId } = useProviderContext();

  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (providersStatus === 'idle') {
      dispatch(fetchProviders());
    }
    if (subscriptionsStatus === 'idle') {
      dispatch(fetchSubscriptions());
    }
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
    // if (subscriptionsStatus === 'idle') {
    //   dispatch(fetchSubscriptionSummary());
    // }
  }, [dispatch, providersStatus, subscriptionsStatus, usersStatus, subscriptionsStatus]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      onCell: () => ({
        style: {
          height: '40px',
          lineHeight: '40px'
        }
      }),
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      onCell: () => ({
        style: {
          height: '40px',
          lineHeight: '40px'
        }
      }),
    },
    {
      title: 'Users',
      dataIndex: 'Users',
      key: 'Users',
      onCell: () => ({
        style: {
          height: '40px',
          lineHeight: '40px'
        }
      }),
    },
    {
      title: 'SMS',
      dataIndex: 'SMS',
      key: 'SMS',
      onCell: () => ({
        style: {
          height: '40px',
          lineHeight: '40px'
        }
      }),
    },
    {
      title: 'Invoices',
      dataIndex: 'Invoices',
      key: 'Invoices',
      onCell: () => ({
        style: {
          height: '40px',
          lineHeight: '40px'
        }
      }),
    },
    {
      title: 'Status',
      key: 'Status',
      onCell: () => ({
        style: {
          height: '40px',
          lineHeight: '40px'
        }
      }),
      dataIndex: 'Status',
      render: (status: string) => {
        let color = 'blue';
        if (status === 'Monthly') {
          color = 'blue';
        } else if (status === 'Trial') {
          color = 'orange';
        } else if (status === 'Yearly') {
          color = 'red';
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
  ];

  const getSMSCountLeftForCentre = (subscriptionId: any) => {
    const subscription = subscriptions.find(sub => sub.subscription_id === subscriptionId);
    return subscription ? subscription.smsCountLeft.toString() : 'N/A';
  };

  const getInvoicesCountLeftForCentre = (subscriptionId: any) => {
    const subscription = subscriptions.find(sub => sub.subscription_id === subscriptionId);
    return subscription ? subscription.invoiceCountLeft.toString() : 'N/A';
  };

  const mappedData: DataType[] = providers.map(provider => ({
    key: provider.centre_id,
    ID: provider.centre_id,
    Name: provider.name,
    Users: users.length.toString(),
    SMS: getSMSCountLeftForCentre(provider.subscription_fk),
    Invoices: getInvoicesCountLeftForCentre(provider.subscription_fk),
    Status: 'Monthly',
  }));

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = mappedData.filter(
    item =>
      item.ID.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Users.toLowerCase().includes(searchText.toLowerCase()) ||
      item.SMS.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Invoices.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Status.toLowerCase().includes(searchText.toLowerCase())
  );

  const onRowClick = (record: DataType) => {
    setSelectedProviderId(record.key);
    onAccountSelect?.();
  };

  return (
    <div>
      <div style={headingStyle}>Account Overview</div>
      <div style={{ display: 'flex', gap: '16px', marginBottom: '21px' }}>
        <Card style={{ width: '405px', height: '217px' }}>
          <div style={{ width: '300px', height: '153px', padding: '32px', display: 'flex', gap: '32px' }}>
            <div style={borderStyle_Card_Icon_1}>
              <TeamOutlined style={{ color: '#fff', fontSize: '50px' }} />
            </div>
            <div>
              <div style={textStyle_Trial}>Trials</div>
              <div style={textStyle_Count}>{subscriptionSummary?.trial ?? 0}</div>
              <div style={{ display: 'flex', gap: '3px', paddingTop: '11px' }}>
                <div style={textStyle_Per}>per Year</div>
                <ArrowUpOutlined />
                <div style={{ color: '#52C41A' }}>{subscriptionSummary?.increasedTrialYear ?? 0}</div>
              </div>
            </div>
          </div>
        </Card>
        <Card style={{ width: '364px', height: '217px' }}>
          <div style={{ width: '300px', height: '153px', padding: '32px', display: 'flex', gap: '32px' }}>
            <div style={borderStyle_Card_Icon_2}>
              <TeamOutlined style={{ color: '#fff', fontSize: '50px' }} />
            </div>
            <div>
              <div style={textStyle_Trial}>Monthly</div>
              <div style={textStyle_Count}>{subscriptionSummary?.monthly ?? 0}</div>
              <div style={{ display: 'flex', gap: '3px', paddingTop: '11px' }}>
                <div style={textStyle_Per}>per Year</div>
                <ArrowUpOutlined />
                <div style={{ color: '#52C41A' }}>{subscriptionSummary?.increasedMonthlyYear ?? 0}</div>
              </div>
            </div>
          </div>
        </Card>
        <Card style={{ width: '364px', height: '217px' }}>
          <div style={{ width: '300px', height: '153px', padding: '32px', display: 'flex', gap: '32px' }}>
            <div style={borderStyle_Card_Icon_3}>
              <TeamOutlined style={{ color: '#fff', fontSize: '50px' }} />
            </div>
            <div>
              <div style={textStyle_Trial}>Yearly</div>
              <div style={textStyle_Count}>{subscriptionSummary?.yearly ?? 0}</div>
              <div style={{ display: 'flex', gap: '3px', paddingTop: '11px' }}>
                <div style={textStyle_Per}>per Year</div>
                <ArrowUpOutlined />
                <div style={{ color: '#52C41A' }}>{subscriptionSummary?.increasedYearlyYear ?? 0}</div>
              </div>
            </div>
          </div>
        </Card>
        <Card style={{ maxWidth: '364px', height: '217px' }}>
          <div style={{ maxWidth: '300px', height: '153px', padding: '32px', display: 'flex', gap: '32px' }}>
            <div>
              <div style={{ display: 'flex', gap: '3px', alignContent: 'centre' }}>
                <div style={textStyle_Trial}>Growth Per Year</div>
                <ArrowUpOutlined />
                <div style={{ color: '#52C41A' }}>{subscriptionSummary?.totalIncreasedYearly ?? 0}</div>
              </div>
              <div style={{ display: 'flex', paddingTop: '11px' }}></div>
            </div>
          </div>
        </Card>
      </div>
      <div style={borderStyle}>
        <div style={{ maxWidth: '1440px', minHeight: '675px', margin: '32px' }}>
          <div style={TitleStyle}>Account List</div>
          <Search
            style={{ maxWidth: '1440px', height: '43px', marginBottom: '32px' }}
            placeholder="Search"
            onChange={handleSearch}
            enterButton
          />
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{ pageSize: 6 }}
            onRow={(record) => ({
              onClick: () => onRowClick(record),
            })}
          />
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default FormDashboardMain;

const headingStyle = {
  color: "var(--colorTextHeading, rgba(0, 0, 0, 0.88))",
  fontFamily: 'SF Pro Text',
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "32px",
  flex: '1 0 0',
  marginBottom: '32px'
};

const TitleStyle = {
  color: "var(--colorTextHeading, rgba(0, 0, 0, 0.88))",
  fontFamily: 'SF Pro Text',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
  flex: '1 0 0',
  marginBottom: '32px'
};

const borderStyle = {
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  maxWidth: '1504px', height: '738px'
};

const borderStyle_Card_Icon_1 = {
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  width: '53px', height: '53px',
  background: '#FAAD14'
};
const borderStyle_Card_Icon_2 = {
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  width: '53px', height: '53px',
  background: '#1677FF'
};
const borderStyle_Card_Icon_3 = {
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  width: '53px', height: '53px',
  background: '#FF4D4F'
};

const textStyle_Trial = {
  color: "var(--colorTextDescription, rgba(0, 0, 0, 0.45))",
  fontFamily: 'SF Pro Text',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
};
const textStyle_Count = {
  color: "var(--colorText, rgba(0, 0, 0, 0.88))",
  fontFamily: 'SF Pro Text',
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "32px",
};
const textStyle_Per = {
  color: "var(--colorText, rgba(0, 0, 0, 0.88))",
  fontFamily: 'SF Pro Text',
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};
