import React, { useState } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, Select, Switch, Space, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import type { Moment } from 'moment';

interface TaxRate {
    key: string;
    name: string;
    rate: number;
    validFrom: Moment;
    validUntil?: Moment;
    type: string;
    active: boolean;
    countryCode: string;
    additionalMwSt: string;
}

const { Option } = Select;

const FormTaxes: React.FC = () => {
    const [data, setData] = useState<TaxRate[]>([
        { key: '1', name: 'Standard VAT', rate: 7.7, validFrom: moment('2024-01-01'), validUntil: undefined, type: '1', active: true, countryCode: 'DE', additionalMwSt: 'None' },
        { key: '2', name: 'Reduced VAT', rate: 2.5, validFrom: moment('2024-01-01'), validUntil: undefined, type: '2', active: true, countryCode: 'FR', additionalMwSt: 'None' },
        { key: '3', name: 'Special VAT (Accommodation)', rate: 3.7, validFrom: moment('2024-01-01'), validUntil: moment('2024-11-21'), type: '0', active: true, countryCode: 'CH', additionalMwSt: 'None' }
    ]);

    const [editingKey, setEditingKey] = useState<string | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [modalForm] = Form.useForm();

    const isEditing = (record: TaxRate) => record.key === editingKey;

    const showModal = () => {
        modalForm.resetFields();
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleModalOk = async () => {
        try {
            const values = await modalForm.validateFields();
            const newTaxRate: TaxRate = {
                key: (data.length + 1).toString(),
                name: values.name,
                rate: values.rate,
                validFrom: values.validFrom,
                validUntil: values.validUntil,
                type: values.type,
                active: values.active,
                countryCode: values.countryCode,
                additionalMwSt: values.additionalMwSt,
            };
            setData([...data, newTaxRate]);
            setIsModalVisible(false);
        } catch (error) {
            console.log('Failed to add tax rate:', error);
        }
    };

    const edit = (record: TaxRate) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey(null);
    };

    const save = async (key: string) => {
        try {
            const row = (await form.validateFields()) as TaxRate;
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey(null);
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey(null);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleDelete = (key: string) => {
        setData(prevData => prevData.filter(item => item.key !== key));
    };

    const handleSwitchChange = (key: string, checked: boolean) => {
        const newData = data.map((item) =>
            item.key === key ? { ...item, active: checked } : item
        );
        setData(newData);
    };

    const migrate = () => {
        console.log("migrate");
    };

    const columns = [
        {
            title: 'Countrycode',
            dataIndex: 'countryCode',
            key: 'countryCode',
            editable: false,
        },
        {
            title: 'Code',
            dataIndex: 'type',
            key: 'type',
            editable: true,
            render: (text: string) => (
                <Select defaultValue={text} style={{ width: '100%' }}>
                    <Option value="0">0</Option>
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                </Select>
            )
        },
        {
            title: 'Mehrwertsteuer',
            dataIndex: 'name',
            key: 'name',
            editable: true,
        },
        {
            title: 'Satz (%)',
            dataIndex: 'rate',
            key: 'rate',
            editable: true,
            render: (rate: number) => `${rate}%`
        },
        {
            title: 'Gültig ab',
            dataIndex: 'validFrom',
            key: 'validFrom',
            editable: true,
            render: (date: Moment) => date.format('YYYY-MM-DD')
        },
        {
            title: 'Gültig bis',
            dataIndex: 'validUntil',
            key: 'validUntil',
            editable: true,
            render: (date?: Moment) => date ? date.format('YYYY-MM-DD') : 'N/A'
        },
        {
            title: 'Aktionen',
            key: 'actions',
            render: (_: any, record: TaxRate) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space size="middle">
                        <Button onClick={() => save(record.key)} type="link">Save</Button>
                        <Button onClick={cancel} type="link">Cancel</Button>
                    </Space>
                ) : (
                    <Space size="middle">
                        <Button disabled={editingKey !== null} onClick={() => edit(record)} type="link">Edit</Button>
                        <Popconfirm title="Are you sure to delete this tax rate?" onConfirm={() => handleDelete(record.key)}>
                            <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: TaxRate) => ({
                record,
                inputType: col.dataIndex === 'rate' ? 'number' : col.dataIndex.includes('valid') ? 'date' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const EditableCell: React.FC<any> = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        children,
        ...restProps
    }) => {
        const inputNode =
            inputType === 'number' ? (
                <Input type="number" />
            ) : inputType === 'date' ? (
                <DatePicker format="YYYY-MM-DD" />
            ) : inputType === 'select' ? (
                <Select defaultValue={record[dataIndex]} style={{ width: '100%' }}>
                    <Option value="Standard">Standard</Option>
                    <Option value="Reduced">Reduced</Option>
                    <Option value="Special">Special</Option>
                </Select>
            ) : (
                <Input />
            );

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                        initialValue={inputType === 'date' && record[dataIndex] ? moment(record[dataIndex]) : record[dataIndex]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    return (
        <div style={{ padding: '24px' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                    MwSt hinzufügen
                </Button>

            </div>

            <Modal
                title="Add New Tax Rate"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Add"
                cancelText="Cancel"
            >
                <Form form={modalForm} layout="vertical">
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the tax rate name' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="rate" label="Rate (%)" rules={[{ required: true, message: 'Please enter the tax rate' }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="validFrom" label="Valid From" rules={[{ required: true, message: 'Please select a start date' }]}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item name="validUntil" label="Valid Until">
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item name="type" label="Code" rules={[{ required: true, message: 'Please select a type' }]}>
                        <Select>
                            <Option value="0">0</Option>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="countryCode" label="CountryCode" rules={[{ required: true, message: 'Please select a country' }]}>
                        <Select>
                            <Option value="de">DE</Option>
                            <Option value="fr">FR</Option>
                            <Option value="ch">CH</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="active" label="Active" valuePropName="checked">
                        <Switch defaultChecked />
                    </Form.Item>
                </Form>
            </Modal>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />
            </Form>
        </div>
    );
};

export default FormTaxes;
