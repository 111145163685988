import React, { useEffect, useState } from 'react';
import { Input, Button, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useProviderContext } from '../components/ProviderContext';
import { fetchProviders, postNote, updateNote } from '../features/providers/providersSlice';

const FormNotes: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedProviderId } = useProviderContext();
  const [form] = Form.useForm();
  const [noteText, setNoteText] = useState<string>('');

  const provider = useSelector((state: RootState) =>
    state.providers.data.find(prov => prov.centre_id === selectedProviderId)
  );

  useEffect(() => {
    if (provider && provider?.Note?.length > 0) {
      setNoteText(provider?.Note[0]?.noteText || '');
      form.setFieldsValue({ notes: provider?.Note[0]?.noteText });
    }
  }, [provider, form]);

  const onFinish = async (values: { notes?: string }) => {
    if (provider && provider.centre_id) {
      const dataForNote = {
        noteText: values.notes || '', 
        category: "General",
        status: "Active",
        data: {
          _id: provider?.centre_id
        }
      };

      if (provider?.Note && provider?.Note?.length > 0) {
        await dispatch(updateNote({ id: provider?.Note[0].note_id, noteData: dataForNote }));
      } else {
        await dispatch(postNote(dataForNote));
      }
      dispatch(fetchProviders());
    } else {
      console.error("Provider or centre_id is not defined");
    }
  };

  const handleCancel = () => {
    form.resetFields(); 
    setNoteText(provider?.Note[0]?.noteText || '');
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteText(e.target.value);
  };

  return (
    <div style={borderStyle_Card}>
      <div style={{ maxWidth: '1323px', height: '285px', padding: '32px' }}>
        <div style={headingStyle}>Notes</div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{ marginBottom: 'auto' }}
        >
          <Form.Item name="notes">
            <TextArea
              placeholder="Enter something"
              value={noteText}
              onChange={handleNoteChange}
              style={{ maxWidth: '1323px', height: '141px', marginBottom: '32px' }}
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ margin: '0 8px' }}>
              <Button onClick={handleCancel}>Cancel</Button>
            </div>
            <div>
              <Button type="primary" htmlType="submit">
                Save changes
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FormNotes;

const borderStyle_Card = {
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  maxWidth: '1387px', height: '349px',
};

const headingStyle = {
  color: "var(--colorTextHeading, rgba(0, 0, 0, 0.88))",
  fontFamily: 'SF Pro Text',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
  flex: '1 0 0',
  marginBottom: '32px'
};
