import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface SignInParams {
    email: string;
    password: string;
}

interface SignInResponse {
    user: any;
    secretKey: string;
    signInToken: string;
    refreshToken: string;
}

const commonServiceApiUrl = process.env.REACT_APP_COMMON_SERVICE_API_URL;

export const signIn = createAsyncThunk<SignInResponse, SignInParams, { rejectValue: string }>(
    'auth/signIn',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await axios.post<SignInResponse>(`${commonServiceApiUrl}/api/adminPanel/signIn`, { email, password });
            return response.data;
        } catch (error: any) {  // Note: Using `any` for simplicity, better to define an error interface
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            }
            return rejectWithValue('Failed to sign in');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isSigningIn: false,
        signInSuccess: false,
        signInError: false,
        errorMessage: ''
    },
    reducers: {
        resetAuthState: (state) => {
            state.signInError = false;
            state.errorMessage = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.isSigningIn = true;
                state.signInError = false;
                state.signInSuccess = false;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.isSigningIn = false;
                state.signInSuccess = true;
                state.user = action.payload.user;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.isSigningIn = false;
                state.signInError = true;
                if (typeof action.payload === 'string') {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = 'Unknown error occurred';
                }
            });
    }
});

export const { resetAuthState } = authSlice.actions;
export default authSlice.reducer;
